<template>
  <section class="hero is-fullheight">
    <ModalAdministrator
      v-if="modalAddAdministratorActive"
      :modeEdit="false"
      :active="modalAddAdministratorActive"
      @close-modal="modalAddAdministratorActive = false"
      :createUser="createUser"
    />
    <ModalAdministrator
      v-if="modalEditdministratorActive"
      :modeEdit="true"
      :data-admin="dataAdmin"
      :active="modalEditdministratorActive"
      :editUserInfo="editUserInfo"
      @close-modal="modalEditdministratorActive = false"
    />
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Administradores
                  </h1>
                </div>
              </div>
              <div class="level-right">
                <!-- <div class="level-item">
                  <input class="input" type="text" placeholder="Buscador">
                </div> -->
                <div class="level-item">
                  <button class="button btn_violet" @click="modalAddAdministratorActive = true">
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12 is_grid pb-0">
            <div class="table-container">
              <BaseTable
                :columns="columnsData"
                :data="usersList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p
                    class="text_status has-text-weight-medium"
                    :class="[ scope.row.active ? 'has_success' : 'has_danger' ]"
                  >
                    {{ scope.row.active === true ? 'Activo' : 'Inactivo'}}
                  </p>
                </div>

                <div slot="actions" slot-scope="scope">
                  <p
                    class="text_action has-text-weight-medium"
                    @click="editUser(scope.row)"
                  >
                    Editar
                  </p>
                </div>
              </BaseTable>
            </div>
          </div>
          <div class="column is-12 pt-0">
            <div class="pagination_container">
              <BasePagination
                :page="page"
                :pages="parseInt(pages)"
                :action="paginator"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AdministratorsMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination'),
    ModalAdministrator: () => import('@/components/Administrators/ModalAdministrator.vue')
  },
  data () {
    return {
      loading: false,
      modalAddAdministratorActive: false,
      modalEditdministratorActive: false,
      page: 1,
      pages: 1,
      limit: 10,
      dataAdmin: {},
      columnsData: [
        { id: 'id', header: 'ID' },
        { id: 'name', header: 'Usuario' },
        { id: 'email', header: 'Correo ' },
        { id: 'active', name: 'status', header: 'Estatus', class: 'has-text-centered' },
        { id: 'actions', name: 'actions', header: 'Acciones', class: 'has-text-centered' }
      ],
      usersList: []
    }
  },
  methods: {
    ...mapActions(['getAdminUserList', 'createAdminUser', 'editAdminUser']),
    editUser (data) {
      this.modalEditdministratorActive = true
      this.dataAdmin = data
    },
    async paginator (page) {
      this.page = page
      await this.getUsersList(this.page, this.limit)
    },
    async getUsersList (page = 1, limit = 10) {
      this.loading = true
      const resp = await this.getAdminUserList({ page, limit })
      if (resp.success) {
        this.usersList = resp.payload
        this.pages = resp.totalPages
      }
      this.loading = false
    },
    async createUser (userInfo) {
      const resp = await this.createAdminUser(userInfo)
      if (resp.success) {
        await this.getUsersList(1, this.limit)
        this.modalAddAdministratorActive = false
      }
      return resp
    },
    async editUserInfo (userId, data) {
      const resp = await this.editAdminUser({ userId, data })
      if (resp.success) {
        await this.getUsersList(1, this.limit)
        this.modalAddAdministratorActive = false
      }
      return resp
    }
  },
  beforeMount () {
    this.getUsersList(this.page, this.limit)
  }
}
</script>

<style lang="scss" scoped>
  .input {
    border-radius: 3px;
    background-color: #fff;
    border: none;
    min-width: 300px;
  }
  .btn_violet {
    height: 3em;
  }
  .text_status, .text_action {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.13px;
  }
  .text_action {
    color: #3bcad6;
    cursor: pointer;
  }
</style>
